$(window).load(function() {
    //----------------------------------------------------
    //      Generic accessibility helper
    //----------------------------------------------------
    $(document).on('mousedown touchdown', 'a, button, input, select, input[type=radio] + label, .button, textarea', function(e) {
        $(this).addClass('no-focus');
    }).on('blur touchend', function(e) {
        $(this).removeClass('no-focus');
    });

    //----------------------------------------------------
    //      How it works tab nav/phader
    //----------------------------------------------------
    $('#darthPhader').darthphader({
        'animationDuration' : 10,
        'waitTime' : 6000,
        'autoPhader' : false,
        'crossfade' : true,
        'slide' : '.slide',
        'container' : '.container',
        'accessible' : true,
        'showNav' : true,
        'navStyle' : 'buttons',
        'buttonClass' : '.button'
    });

    //----------------------------------------------------
    //      Mobile menu open/close
    //----------------------------------------------------
    $('#mobMenuOpen').on('click', function(e) {
        e.preventDefault();
        $(this).fadeOut(50);
        $('header nav').addClass('active');
    });

    $('#mobMenuClose').on('click', function(e) {
        e.preventDefault();
        $('header nav').removeClass('active');
        $('#mobMenuOpen').fadeIn(2000);
    });

    //----------------------------------------------------
    //      Pricing calculator
    //      'digits' - hat tip:
    //      http://stackoverflow.com/questions/1990512/add-comma-to-numbers-every-three-digits-using-jquery
    //----------------------------------------------------
    $.fn.digits = function(){
        return this.each(function(){
            $(this).text( $(this).text().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") );
        })
    }

    $('#calculator input').on('input', function(e) {
        e.preventDefault();

        //ok, go work it out
        var users = ( !$.isNumeric(parseInt($(this).val(), 10)) ) ? 0 : parseInt($(this).val(), 10);

        if( !$(this).val().match(/^\d+$/) ) {
            alert('Please enter only numbers.');
            $(this).val();
        } else {

            var licence = 2000,
                perUser = 8,
                userCost = (users * perUser);

            if ( users <= 1000 ) {
                var total = userCost + licence;
                $('#calculator span.setupFee').text( '£' + licence ).digits();
            } else {
                var total = (userCost * 0.25) + userCost;
                $('#calculator span.setupFee').text( '£' + (userCost * 0.25) ).digits();
            }

            $('#calculator span.total').text( '£' + total ).digits();
        }
    });

    //----------------------------------------------------
    //      Activities filtering
    //----------------------------------------------------
    $('.filtering button').on('click', function(e) {
        e.preventDefault();

        $(this).addClass('active').siblings('button').removeClass('active');

        var filterBy = $(this).attr('data-type');
        console.log(filterBy);

        if ( filterBy == 'all' ) {
            $('.activitiesList li').addClass('active');
        } else {
            $('.activitiesList li').removeClass('active');
            $('.activitiesList li[data-activitycategory*='+ filterBy + ']').addClass('active');
        }
    });

    //----------------------------------------------------
    //      THE JUICE MACHINE
    //----------------------------------------------------
    $('#juiceMachine').juicemachine();

    //----------------------------------------------------
    //      scroll nicely back to the top on mobile
    //----------------------------------------------------
    $('.backToTop a').on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 });
    });
});
